import React from "react";
import styled from "@emotion/styled";
// components
import { DefaultLayout } from "../../components/layout/defaultLayout";
import { PageHeading } from "../../components/heading/pageHeading";
import { BreadcrumbBar } from "../../components/bar/breadcrumbBar";
import { SectionHeading } from "../../components/heading/sectionHeading";
import { ItemCard } from "../../components/card/itemCard";
// utils
import { Color } from "../../utils/color";
import { ItemLists } from "../../utils/itemLists";
// breadcrumb
const breadcrumbs = [
  { txt: "トップ", url: "/" },
  { txt: "ブランドから探す", url: "/brand" },
  { txt: "ブランド01", url: "/brand/brand01" },
];
// brand === brand01を抽出
const Brand01ItemLists = ItemLists.filter((Item) => {
  return Item.brand === "brand01";
});

const Brand01Page = () => {
  return (
    <DefaultLayout pageTitle="ブランド01">
      <PageHeading enTxt="BRAND01" jpTxt="ブランド01" />
      <BreadcrumbBar breadcrumbs={breadcrumbs} />
      <SSection>
        <SContainer>
          <SectionHeading txt="ブランド01の製品" />
          <SWrapper>
            {Brand01ItemLists.map(({ id, name, price, brand, url, img }) => {
              return (
                <ItemCard
                  key={id}
                  name={name}
                  price={price}
                  brand={brand}
                  url={url}
                  img={img}
                />
              );
            })}
          </SWrapper>
        </SContainer>
      </SSection>
    </DefaultLayout>
  );
};

export default Brand01Page;

const SSection = styled.section`
  padding: 4rem 0;
  border-bottom: 1px solid ${Color.gray100};
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    padding: 2rem 0;
  }
`;

const SContainer = styled.div`
  width: 96%;
  max-width: 1366px;
  margin: auto;
`;

const SWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(200px, 100%), 1fr));
  gap: 1rem;
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    grid-template-columns: repeat(auto-fit, minmax(min(160px, 100%), 1fr));
    gap: 0.5rem;
  }
`;
